/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import { UserRole } from './apollo/legacyTypes';
import { UserPermission } from './apollo/legacyTypes';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Currency scalar type to represent currency values */
  Currency: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** `Date` type as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: any; output: any; }
};

export type Advertisement = {
  __typename?: 'Advertisement';
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  repeats?: Maybe<Scalars['Int']['output']>;
};

export type Avatar = {
  __typename?: 'Avatar';
  cropSettings?: Maybe<Scalars['JSON']['output']>;
  originalUrl: Scalars['String']['output'];
};

export type AvatarParams = {
  cropSettings?: InputMaybe<Scalars['JSON']['input']>;
  originalUrl: Scalars['String']['input'];
};

export type Comment = {
  __typename?: 'Comment';
  author: User;
  body: Scalars['String']['output'];
  children?: Maybe<Array<Comment>>;
  createdAt: Scalars['Timestamp']['output'];
  deleted?: Maybe<CommentDeleted>;
  id: Scalars['ID']['output'];
  /** @deprecated use deleted */
  isDeleted: Scalars['Boolean']['output'];
  isPessimized: Scalars['Boolean']['output'];
  modifiedAt: Scalars['Timestamp']['output'];
  parent?: Maybe<Comment>;
  post: MediaPost;
  reactions: Reactions;
  replyTo?: Maybe<Comment>;
};

export type CommentAddedNotification = Notification & {
  __typename?: 'CommentAddedNotification';
  comment: Comment;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  isViewed: Scalars['Boolean']['output'];
};

export type CommentDeleted = {
  __typename?: 'CommentDeleted';
  deletedAt: Scalars['Timestamp']['output'];
  deletedBy: User;
};

export type CommentFilterInput = {
  createdAtFrom?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtTo?: InputMaybe<Scalars['Timestamp']['input']>;
  isPessimized?: InputMaybe<Scalars['Boolean']['input']>;
  modifiedAtFrom?: InputMaybe<Scalars['Timestamp']['input']>;
  modifiedAtTo?: InputMaybe<Scalars['Timestamp']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  postId?: InputMaybe<Scalars['ID']['input']>;
  postIdentity?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CommentList = {
  __typename?: 'CommentList';
  items: Array<Comment>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum CommentOrderField {
  Author = 'AUTHOR',
  CreatedAt = 'CREATED_AT',
  DislikesCount = 'DISLIKES_COUNT',
  LikesCount = 'LIKES_COUNT',
  ModifiedAt = 'MODIFIED_AT',
  Popularity = 'POPULARITY'
}

export type CommentOrderInput = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<CommentOrderField>;
};

export type Contacts = {
  __typename?: 'Contacts';
  fb?: Maybe<Scalars['String']['output']>;
  tg?: Maybe<Scalars['String']['output']>;
  vk?: Maybe<Scalars['String']['output']>;
};

export type ContactsUpdateParams = {
  fb?: InputMaybe<Scalars['String']['input']>;
  tg?: InputMaybe<Scalars['String']['input']>;
  vk?: InputMaybe<Scalars['String']['input']>;
};

export type Content = {
  __typename?: 'Content';
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: User;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['Timestamp']['output'];
  preview?: Maybe<Scalars['String']['output']>;
  status: ContentStatus;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export enum ContentStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type Cover = {
  __typename?: 'Cover';
  alt?: Maybe<Scalars['String']['output']>;
  cropSettings?: Maybe<Scalars['JSON']['output']>;
  originalUrl: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
};

export type CreateAdvertisementInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  repeats?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateCommentInput = {
  body: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['String']['input'];
  replyToId?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePostInput = {
  bodyType?: InputMaybe<PostBodyType>;
  kind?: InputMaybe<PostKind>;
  publishAt: Scalars['Timestamp']['input'];
  species?: InputMaybe<PostSpecies>;
  type?: InputMaybe<PostType>;
};

export type CreateTagInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<UpdateCoverInput>;
  type?: InputMaybe<TagType>;
  value: Scalars['String']['input'];
};

export type DailyPosts = {
  __typename?: 'DailyPosts';
  date: Scalars['Timestamp']['output'];
  posts: Array<MediaPost>;
};

export type DailyPostsFilter = {
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  stream?: InputMaybe<Stream>;
  withoutPins?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DailyPostsList = {
  __typename?: 'DailyPostsList';
  items: Array<DailyPosts>;
  pageInfo: PageInfo;
};

export type FavoritesFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FeedPostsFilter = {
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  stream?: InputMaybe<Stream>;
};

export type FeedbackInput = {
  captchaToken: Scalars['String']['input'];
  from: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export enum GqlError {
  AdvertisementNotFound = 'ADVERTISEMENT_NOT_FOUND',
  CantModifyAfterPublication = 'CANT_MODIFY_AFTER_PUBLICATION',
  ChangeAliasTooOften = 'CHANGE_ALIAS_TOO_OFTEN',
  ChangeNicknameTooOften = 'CHANGE_NICKNAME_TOO_OFTEN',
  CommentNotFound = 'COMMENT_NOT_FOUND',
  ConfirmationCodeNotMatches = 'CONFIRMATION_CODE_NOT_MATCHES',
  ContentNotFound = 'CONTENT_NOT_FOUND',
  Forbidden = 'FORBIDDEN',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  InvalidUserAlias = 'INVALID_USER_ALIAS',
  PasswordNotSet = 'PASSWORD_NOT_SET',
  PostAlreadyPublished = 'POST_ALREADY_PUBLISHED',
  PostCommentsDisabled = 'POST_COMMENTS_DISABLED',
  PostNotFound = 'POST_NOT_FOUND',
  PostNotPublished = 'POST_NOT_PUBLISHED',
  PostNotReadyToPublish = 'POST_NOT_READY_TO_PUBLISH',
  PostPublicationInPast = 'POST_PUBLICATION_IN_PAST',
  TagAliasAlreadyExists = 'TAG_ALIAS_ALREADY_EXISTS',
  TagNotFound = 'TAG_NOT_FOUND',
  TagWrongMergeTarget = 'TAG_WRONG_MERGE_TARGET',
  TweetNotFound = 'TWEET_NOT_FOUND',
  Unauthenticated = 'UNAUTHENTICATED',
  UserAliasAlreadyExist = 'USER_ALIAS_ALREADY_EXIST',
  UserAlreadyExist = 'USER_ALREADY_EXIST',
  UserNicknameAlreadyExist = 'USER_NICKNAME_ALREADY_EXIST',
  UserNotFound = 'USER_NOT_FOUND',
  UserThirdPartyIdsAlreadyExist = 'USER_THIRD_PARTY_IDS_ALREADY_EXIST',
  UseRequestEmailChange = 'USE_REQUEST_EMAIL_CHANGE',
  WrongStream = 'WRONG_STREAM'
}

export type LoginInfo = {
  __typename?: 'LoginInfo';
  user?: Maybe<User>;
};

export type MediaPost = {
  __typename?: 'MediaPost';
  areCommentsEnabled: Scalars['Boolean']['output'];
  authors: Array<User>;
  body?: Maybe<Scalars['String']['output']>;
  bodyType: PostBodyType;
  comments: CommentList;
  commentsCount: Scalars['Int']['output'];
  cover?: Maybe<Cover>;
  createdBy: User;
  description: Scalars['String']['output'];
  disabledAdvertisements: Array<Scalars['String']['output']>;
  favorite: Scalars['Boolean']['output'];
  hasUnpublishedChanges?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isAdvertisementEnabled: Scalars['Boolean']['output'];
  isBlog: Scalars['Boolean']['output'];
  isCoverEnabled: Scalars['Boolean']['output'];
  isPreview: Scalars['Boolean']['output'];
  isSpecialProject: Scalars['Boolean']['output'];
  /** @deprecated Use species instead */
  kind: PostKind;
  preview: Scalars['String']['output'];
  promotedPost?: Maybe<MediaPost>;
  publications: Array<Publication>;
  publishAt: Scalars['Timestamp']['output'];
  reactions: Reactions;
  recommendations: Array<MediaPost>;
  recommendationsTitle?: Maybe<Scalars['String']['output']>;
  /** Returns null if no access */
  revenue: PostRevenue;
  searchParams: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  specialProjectType?: Maybe<SpecialProjectType>;
  species: PostSpecies;
  status: PostStatus;
  subtitle: Scalars['String']['output'];
  tags: Array<Tag>;
  title: Scalars['String']['output'];
  /** @deprecated Use bodyType instead */
  type: PostType;
  viewsCount: Scalars['Int']['output'];
};


export type MediaPostCommentsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type MediaPostFilter = {
  author?: InputMaybe<Scalars['ID']['input']>;
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  publishAtFrom?: InputMaybe<Scalars['Timestamp']['input']>;
  publishAtTo?: InputMaybe<Scalars['Timestamp']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  stream?: InputMaybe<Stream>;
  tag?: InputMaybe<Scalars['ID']['input']>;
  tagAlias?: InputMaybe<Scalars['String']['input']>;
  withoutPins?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MediaPostList = {
  __typename?: 'MediaPostList';
  items: Array<MediaPost>;
  pageInfo: MediaPostPageInfo;
};

export type MediaPostPageInfo = {
  __typename?: 'MediaPostPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  minPublishAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type ModerationRejectedNotification = Notification & {
  __typename?: 'ModerationRejectedNotification';
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  isViewed: Scalars['Boolean']['output'];
  mediaPost: MediaPost;
};

export type ModerationRejectionMessageInput = {
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  postId: Scalars['ID']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  actualizeMediaPostPublication: MediaPost;
  /** @deprecated Use publishPostChanges() */
  actualizePublication: Post;
  addMediaPostToFavorites: MediaPost;
  /** @deprecated Use addMediaPostToFavorites() */
  addToFavorites: MediaPost;
  /** @deprecated Use publishPostAtPlannedTime() */
  approve: Post;
  approveMediaPost: MediaPost;
  /** @deprecated Use banUser() */
  ban: User;
  banUser: User;
  /** @deprecated Use changeUserPassword() */
  changePassword: User;
  changeUserPassword: User;
  /** @deprecated Use confirmUserRegistration() */
  confirm: User;
  /** @deprecated Use confirmUserEmailChange() */
  confirmEmailChange: User;
  /** @deprecated Use confirmUserPasswordReset() */
  confirmPasswordReset: Scalars['Boolean']['output'];
  confirmUserEmailChange: User;
  confirmUserPasswordReset: Scalars['Boolean']['output'];
  confirmUserRegistration: User;
  createAdvertisement: Advertisement;
  createComment: Comment;
  createPost: Post;
  createTag: Tag;
  createUser: User;
  deleteAdvertisement: Scalars['ID']['output'];
  deleteComment: Comment;
  deleteCommentsByUser: Array<Comment>;
  deletePost: PostId;
  deletePostsByUser: Array<PostId>;
  deleteTag: Scalars['Boolean']['output'];
  /** @deprecated Use sendMessageToSupport() */
  feedback: Scalars['Boolean']['output'];
  initSession: Scalars['Boolean']['output'];
  login: LoginInfo;
  logout: LoginInfo;
  mergeTags: Tag;
  pinPosts: Pins;
  /** @deprecated Use publishPostNow() */
  publish: Post;
  publishMediaPost: MediaPost;
  publishPostAtPlannedTime: Post;
  publishPostAtPreviousPublicationTime: Post;
  publishPostChanges: Post;
  publishPostNow: Post;
  reactToComment: Comment;
  reactToPost: MediaPost;
  /** @deprecated Use removeMediaPostFromFavorites() */
  removeFromFavorites: MediaPost;
  removeMediaPostFromFavorites: MediaPost;
  removePinEndDate: Scalars['Boolean']['output'];
  /** @deprecated Use publishPostAtPreviousPublicationTime() */
  republish: Post;
  /** @deprecated Use requestUserEmailChange() */
  requestEmailChange: User;
  requestMediaPostModeration: MediaPost;
  /** @deprecated Use requestUserPasswordReset() */
  requestPasswordReset: Scalars['Boolean']['output'];
  requestPostModeration: Post;
  requestUserEmailChange: User;
  requestUserPasswordReset: Scalars['Boolean']['output'];
  restoreComment: Comment;
  rollbackPost: Post;
  sendMessageOnModerationRejection: Scalars['Boolean']['output'];
  sendMessageToSupport: Scalars['Boolean']['output'];
  suggestUserAlias: Scalars['String']['output'];
  /** @deprecated Use unbanUser() */
  unban: User;
  unbanUser: User;
  /** @deprecated Use unpublishPost() */
  unpublish: Post;
  unpublishMediaPost: MediaPost;
  unpublishPost: Post;
  updateAdvertisement: Advertisement;
  updateComment: Comment;
  updateMediaPost: MediaPost;
  updatePinEndDate: Scalars['Boolean']['output'];
  updatePost: Post;
  updateSettings: Settings;
  updateTag: Tag;
  updateUser: User;
  viewAdvertisement: Scalars['Boolean']['output'];
  viewAllNotifications: Array<Notification>;
  viewNotifications: Array<Notification>;
  /** @deprecated use viewPostBySlug() */
  viewPost: MediaPost;
  viewPostBySlug: MediaPost;
};


export type MutationActualizeMediaPostPublicationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActualizePublicationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddMediaPostToFavoritesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddToFavoritesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationApproveArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationApproveMediaPostArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationBanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBanUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationChangePasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationChangeUserPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationConfirmArgs = {
  code: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationConfirmEmailChangeArgs = {
  code: Scalars['String']['input'];
};


export type MutationConfirmPasswordResetArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationConfirmUserEmailChangeArgs = {
  code: Scalars['String']['input'];
};


export type MutationConfirmUserPasswordResetArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationConfirmUserRegistrationArgs = {
  code: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationCreateAdvertisementArgs = {
  dto: CreateAdvertisementInput;
};


export type MutationCreateCommentArgs = {
  dto: CreateCommentInput;
};


export type MutationCreatePostArgs = {
  dto: CreatePostInput;
};


export type MutationCreateTagArgs = {
  dto: CreateTagInput;
};


export type MutationCreateUserArgs = {
  params: UserCreateParams;
};


export type MutationDeleteAdvertisementArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCommentsByUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationDeletePostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePostsByUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFeedbackArgs = {
  dto: FeedbackInput;
};


export type MutationLoginArgs = {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMergeTagsArgs = {
  sourceId: Scalars['ID']['input'];
  targetId: Scalars['ID']['input'];
};


export type MutationPinPostsArgs = {
  id: Stream;
  postIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationPublishArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationPublishMediaPostArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationPublishPostAtPlannedTimeArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationPublishPostAtPreviousPublicationTimeArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationPublishPostChangesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPublishPostNowArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationReactToCommentArgs = {
  id: Scalars['ID']['input'];
  reaction: Reaction;
};


export type MutationReactToPostArgs = {
  id: Scalars['ID']['input'];
  reaction: Reaction;
};


export type MutationRemoveFromFavoritesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveMediaPostFromFavoritesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemovePinEndDateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRepublishArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationRequestEmailChangeArgs = {
  newEmail: Scalars['String']['input'];
};


export type MutationRequestMediaPostModerationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationRequestPostModerationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRequestUserEmailChangeArgs = {
  newEmail: Scalars['String']['input'];
};


export type MutationRequestUserPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationRestoreCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRollbackPostArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


export type MutationSendMessageOnModerationRejectionArgs = {
  dto: ModerationRejectionMessageInput;
};


export type MutationSendMessageToSupportArgs = {
  dto: SupportMessageInput;
};


export type MutationSuggestUserAliasArgs = {
  nickname: Scalars['String']['input'];
};


export type MutationUnbanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnbanUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnpublishArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationUnpublishMediaPostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnpublishPostArgs = {
  id: Scalars['ID']['input'];
  stream?: InputMaybe<Stream>;
};


export type MutationUpdateAdvertisementArgs = {
  dto: UpdateAdvertisementInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateCommentArgs = {
  body: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUpdateMediaPostArgs = {
  dto: UpdatePostInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdatePinEndDateArgs = {
  endDate?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationUpdatePostArgs = {
  dto: UpdatePostInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateSettingsArgs = {
  dto: UpdateSettings;
};


export type MutationUpdateTagArgs = {
  dto: UpdateTagInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  params: UserUpdateParams;
};


export type MutationViewAdvertisementArgs = {
  advertisementIds: Array<Scalars['ID']['input']>;
  postId: Scalars['ID']['input'];
};


export type MutationViewNotificationsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationViewPostArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identity?: InputMaybe<Scalars['String']['input']>;
  referrerIdentity?: InputMaybe<Scalars['String']['input']>;
};


export type MutationViewPostBySlugArgs = {
  referrerSlug?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type MyPostsFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<PostStatus>>;
};

export type Notification = {
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  isViewed: Scalars['Boolean']['output'];
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

export type NotificationFilterInput = {
  isViewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OEmbedInstagram = {
  __typename?: 'OEmbedInstagram';
  authorName: Scalars['String']['output'];
  html: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  providerUrl: Scalars['String']['output'];
  thumbnailHeight: Scalars['Int']['output'];
  thumbnailUrl: Scalars['String']['output'];
  thumbnailWidth: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  version: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type OEmbedInstagramInput = {
  hideCaption?: InputMaybe<Scalars['Boolean']['input']>;
  maxWidth?: InputMaybe<Scalars['Int']['input']>;
  omitScript?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

export type OEmbedTweet = {
  __typename?: 'OEmbedTweet';
  authorName: Scalars['String']['output'];
  authorUrl: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  html: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  version: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type OEmbedTweetInput = {
  align?: InputMaybe<TweetAlign>;
  dnt?: InputMaybe<Scalars['Boolean']['input']>;
  hideMedia?: InputMaybe<Scalars['Boolean']['input']>;
  hideThread?: InputMaybe<Scalars['Boolean']['input']>;
  lang?: InputMaybe<TwitterLang>;
  linkColor?: InputMaybe<Scalars['String']['input']>;
  maxWidth?: InputMaybe<Scalars['Int']['input']>;
  omitScript?: InputMaybe<Scalars['Boolean']['input']>;
  related?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<TweetTheme>;
  url: Scalars['String']['input'];
  widgetType?: InputMaybe<TweetWidgetType>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Pagination = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type PinnedMediaPost = {
  __typename?: 'PinnedMediaPost';
  areCommentsEnabled: Scalars['Boolean']['output'];
  authors: Array<User>;
  body?: Maybe<Scalars['String']['output']>;
  bodyType: PostBodyType;
  comments: CommentList;
  commentsCount: Scalars['Int']['output'];
  cover?: Maybe<Cover>;
  createdBy: User;
  description: Scalars['String']['output'];
  disabledAdvertisements: Array<Scalars['String']['output']>;
  favorite: Scalars['Boolean']['output'];
  hasUnpublishedChanges?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isAdvertisementEnabled: Scalars['Boolean']['output'];
  isBlog: Scalars['Boolean']['output'];
  isCoverEnabled: Scalars['Boolean']['output'];
  isPreview: Scalars['Boolean']['output'];
  isSpecialProject: Scalars['Boolean']['output'];
  /** @deprecated Use species instead */
  kind: PostKind;
  pinEndDate?: Maybe<Scalars['Timestamp']['output']>;
  preview: Scalars['String']['output'];
  promotedPost?: Maybe<MediaPost>;
  publications: Array<Publication>;
  publishAt: Scalars['Timestamp']['output'];
  reactions: Reactions;
  recommendations: Array<MediaPost>;
  recommendationsTitle?: Maybe<Scalars['String']['output']>;
  /** Returns null if no access */
  revenue: PostRevenue;
  searchParams: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  specialProjectType?: Maybe<SpecialProjectType>;
  species: PostSpecies;
  status: PostStatus;
  subtitle: Scalars['String']['output'];
  tags: Array<Tag>;
  title: Scalars['String']['output'];
  /** @deprecated Use bodyType instead */
  type: PostType;
  viewsCount: Scalars['Int']['output'];
};


export type PinnedMediaPostCommentsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type Pins = {
  __typename?: 'Pins';
  id: Stream;
  posts?: Maybe<Array<Maybe<PinnedMediaPost>>>;
};

export type Post = {
  __typename?: 'Post';
  alias?: Maybe<Scalars['String']['output']>;
  areCommentsEnabled: Scalars['Boolean']['output'];
  authors: Array<User>;
  bodyType: PostBodyType;
  comments: CommentList;
  content: Content;
  contentHistory: Array<Content>;
  cover?: Maybe<Cover>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: User;
  dailyViewLimit?: Maybe<Scalars['Int']['output']>;
  dailyViewLimitEndDate?: Maybe<Scalars['Timestamp']['output']>;
  disabledAdvertisements: Array<Scalars['String']['output']>;
  editedBy: User;
  hasUnpublishedChanges?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isAdvertisementEnabled: Scalars['Boolean']['output'];
  isCoverEnabled: Scalars['Boolean']['output'];
  isSpecialProject: Scalars['Boolean']['output'];
  /** @deprecated Use species instead */
  kind: PostKind;
  moderationInProgress: Scalars['Boolean']['output'];
  moderationRequired: Scalars['Boolean']['output'];
  moderationSuccessful: Scalars['Boolean']['output'];
  participants: Array<User>;
  publicationModifiedAt: Scalars['Timestamp']['output'];
  publications: Array<Publication>;
  publishAt: Scalars['Timestamp']['output'];
  recommendations: Array<Post>;
  recommendationsTitle?: Maybe<Scalars['String']['output']>;
  responsible: User;
  searchParams?: Maybe<Scalars['String']['output']>;
  serial: Scalars['Int']['output'];
  source?: Maybe<Scalars['String']['output']>;
  specialProjectCost?: Maybe<Scalars['Currency']['output']>;
  specialProjectEndDate?: Maybe<Scalars['Timestamp']['output']>;
  specialProjectType?: Maybe<SpecialProjectType>;
  specialProjectWasPublished?: Maybe<Scalars['Boolean']['output']>;
  species: PostSpecies;
  status: PostStatus;
  tags: Array<Tag>;
  /** @deprecated Use bodyType instead */
  type: PostType;
};


export type PostCommentsArgs = {
  order?: InputMaybe<CommentOrderInput>;
  pagination?: InputMaybe<Pagination>;
};


export type PostContentHistoryArgs = {
  limit: Scalars['Int']['input'];
};

export enum PostBodyType {
  Flame = 'FLAME',
  Legacy = 'LEGACY',
  Verstka = 'VERSTKA'
}

export type PostFilter = {
  kind?: InputMaybe<Array<PostKind>>;
  /** Inclusive */
  publicationModifiedAtFrom?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Exclusive */
  publicationModifiedAtTo?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Inclusive */
  publishAtFrom?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Exclusive */
  publishAtTo?: InputMaybe<Scalars['Timestamp']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  species?: InputMaybe<Array<PostSpecies>>;
  status?: InputMaybe<Array<PostStatus>>;
  tagAlias?: InputMaybe<Scalars['String']['input']>;
};

export type PostId = {
  __typename?: 'PostId';
  id: Scalars['ID']['output'];
};

export enum PostKind {
  Blog = 'BLOG',
  Feature = 'FEATURE',
  SuperFeature = 'SUPER_FEATURE'
}

export type PostList = {
  __typename?: 'PostList';
  items: Array<Post>;
  pageInfo: PageInfo;
};

export enum PostOrderField {
  Author = 'AUTHOR',
  CreatedAt = 'CREATED_AT',
  ModerationInProgress = 'MODERATION_IN_PROGRESS',
  PublicationModifiedAt = 'PUBLICATION_MODIFIED_AT'
}

export type PostOrderInput = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<PostOrderField>;
};

export type PostRevenue = {
  __typename?: 'PostRevenue';
  directSalesAmount?: Maybe<Scalars['Currency']['output']>;
  rtbAmount?: Maybe<Scalars['Currency']['output']>;
  specialProjectAmount?: Maybe<Scalars['Currency']['output']>;
  subscriptionAmount?: Maybe<Scalars['Currency']['output']>;
  totalAmount?: Maybe<Scalars['Currency']['output']>;
  totalTonAmount: Scalars['Currency']['output'];
};

export enum PostSpecies {
  Article = 'ARTICLE',
  Blog = 'BLOG'
}

export enum PostStatus {
  Draft = 'DRAFT',
  Moderation = 'MODERATION',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export enum PostType {
  Article = 'ARTICLE',
  SpecialProject = 'SPECIAL_PROJECT',
  Verstka = 'VERSTKA'
}

export type Publication = {
  __typename?: 'Publication';
  publishAt: Scalars['Timestamp']['output'];
  publishBy: User;
  stream: Stream;
};

export type PublishedPostsFilter = {
  search: Scalars['String']['input'];
  species?: InputMaybe<PostSpecies>;
};

export type Query = {
  __typename?: 'Query';
  advertisement?: Maybe<Advertisement>;
  advertisements: Array<Advertisement>;
  authors: UserList;
  comment?: Maybe<Comment>;
  comments: CommentList;
  content?: Maybe<Content>;
  dailyPosts: DailyPostsList;
  /** Won't be called. Throws UserNotFoundException for exception handling example */
  errorCode: GqlError;
  favorites: MediaPostList;
  feedPosts: MediaPostList;
  isNicknameTaken: Scalars['Boolean']['output'];
  isUserAliasTaken: Scalars['Boolean']['output'];
  isUserEmailTaken: Scalars['Boolean']['output'];
  loginInfo: LoginInfo;
  mediaPost?: Maybe<MediaPost>;
  mediaPostBySlug?: Maybe<MediaPost>;
  mediaPosts: MediaPostList;
  /** @deprecated Use userPosts */
  myPosts: MediaPostList;
  notifications: NotificationConnection;
  oEmbedInstagram?: Maybe<OEmbedInstagram>;
  oEmbedTweet?: Maybe<OEmbedTweet>;
  pins: Pins;
  popularMediaPost?: Maybe<MediaPost>;
  post?: Maybe<Post>;
  posts: PostList;
  publishedPosts: MediaPostList;
  readers: UserList;
  searchedPosts: MediaPostList;
  setkaEditorConfig: SetkaEditorConfig;
  settings: Settings;
  tag?: Maybe<Tag>;
  tagByAlias?: Maybe<Tag>;
  tags: Array<Tag>;
  tagsByOffset: TagsByOffset;
  user?: Maybe<User>;
  userByAlias?: Maybe<User>;
  userPosts: MediaPostList;
  users: UserList;
};


export type QueryAdvertisementArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAuthorsArgs = {
  filter?: InputMaybe<UserFindParams>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCommentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCommentsArgs = {
  filter?: InputMaybe<CommentFilterInput>;
  order?: InputMaybe<CommentOrderInput>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryContentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDailyPostsArgs = {
  filter?: InputMaybe<DailyPostsFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFavoritesArgs = {
  filter?: InputMaybe<FavoritesFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFeedPostsArgs = {
  filter: FeedPostsFilter;
  pagination?: InputMaybe<Pagination>;
};


export type QueryIsNicknameTakenArgs = {
  nickname: Scalars['String']['input'];
};


export type QueryIsUserAliasTakenArgs = {
  alias: Scalars['String']['input'];
};


export type QueryIsUserEmailTakenArgs = {
  email: Scalars['String']['input'];
};


export type QueryMediaPostArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identity?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMediaPostBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryMediaPostsArgs = {
  filter?: InputMaybe<MediaPostFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryMyPostsArgs = {
  filter?: InputMaybe<MyPostsFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOEmbedInstagramArgs = {
  dto: OEmbedInstagramInput;
};


export type QueryOEmbedTweetArgs = {
  dto: OEmbedTweetInput;
};


export type QueryPinsArgs = {
  id: Stream;
};


export type QueryPostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPostsArgs = {
  filter?: InputMaybe<PostFilter>;
  order?: InputMaybe<PostOrderInput>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryPublishedPostsArgs = {
  filter: PublishedPostsFilter;
  pagination?: InputMaybe<Pagination>;
};


export type QueryReadersArgs = {
  filter?: InputMaybe<UserFindParams>;
  pagination?: InputMaybe<Pagination>;
};


export type QuerySearchedPostsArgs = {
  filter?: InputMaybe<MediaPostFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryTagArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTagByAliasArgs = {
  alias: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  filter: TagFilterInput;
  limit: Scalars['Int']['input'];
};


export type QueryTagsByOffsetArgs = {
  filter?: InputMaybe<TagFilterInput>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TagOrderInput>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identity?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserByAliasArgs = {
  alias: Scalars['String']['input'];
};


export type QueryUserPostsArgs = {
  filter: UserPostsFilter;
  pagination?: InputMaybe<Pagination>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFindParams>;
  pagination?: InputMaybe<Pagination>;
};

export enum Reaction {
  Dislike = 'DISLIKE',
  Like = 'LIKE',
  None = 'NONE'
}

export type Reactions = {
  __typename?: 'Reactions';
  dislikesCount: Scalars['Int']['output'];
  likesCount: Scalars['Int']['output'];
  myReaction: Reaction;
};

export type SetkaEditorConfig = {
  __typename?: 'SetkaEditorConfig';
  companyCssUrl: Scalars['String']['output'];
  companyJson: Scalars['JSON']['output'];
  editorCssUrl: Scalars['String']['output'];
  editorJsUrl: Scalars['String']['output'];
  publicToken: Scalars['String']['output'];
};

export type Settings = {
  __typename?: 'Settings';
  theme: Theme;
};

export enum SpecialProjectType {
  Default = 'DEFAULT',
  Promo = 'PROMO'
}

export type Stats = {
  __typename?: 'Stats';
  commentsCount: Scalars['Int']['output'];
  dislikesCount: Scalars['Int']['output'];
  likesCount: Scalars['Int']['output'];
  postsCount: Scalars['Int']['output'];
  tons: Scalars['Currency']['output'];
};

export enum Stream {
  Blog = 'BLOG',
  Main = 'MAIN'
}

export type Subscription = {
  __typename?: 'Subscription';
  notificationReceived: NotificationEdge;
};


export type SubscriptionNotificationReceivedArgs = {
  userId: Scalars['ID']['input'];
};

export type SupportMessageInput = {
  captchaToken: Scalars['String']['input'];
  from: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type Tag = {
  __typename?: 'Tag';
  alias: Scalars['String']['output'];
  birthDate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Cover>;
  target?: Maybe<Tag>;
  type: TagType;
  value: Scalars['String']['output'];
  viewsCount?: Maybe<Scalars['Int']['output']>;
};

export type TagFilterInput = {
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  month?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TagType>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export enum TagOrderField {
  Alias = 'ALIAS',
  Type = 'TYPE',
  Value = 'VALUE',
  ViewsCount = 'VIEWS_COUNT'
}

export type TagOrderInput = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<TagOrderField>;
};

export enum TagType {
  Person = 'PERSON',
  Section = 'SECTION',
  Simple = 'SIMPLE'
}

export type TagsByOffset = {
  __typename?: 'TagsByOffset';
  items: Array<Tag>;
  totalCount: Scalars['Int']['output'];
};

export enum Theme {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export enum ThirdPartyApp {
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Ok = 'OK',
  Twitter = 'TWITTER',
  Vk = 'VK'
}

export enum TweetAlign {
  Center = 'CENTER',
  Left = 'LEFT',
  None = 'NONE',
  Right = 'RIGHT'
}

export enum TweetTheme {
  Dark = 'DARK',
  Light = 'LIGHT'
}

export enum TweetWidgetType {
  Video = 'VIDEO'
}

export enum TwitterLang {
  En = 'EN',
  Ru = 'RU'
}

export type UpdateAdvertisementInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  repeats?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateContentInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCoverInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  cropSettings?: InputMaybe<Scalars['JSON']['input']>;
  originalUrl: Scalars['String']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePostInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  areCommentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  authors?: InputMaybe<Array<Scalars['ID']['input']>>;
  bodyType?: InputMaybe<PostBodyType>;
  content?: InputMaybe<UpdateContentInput>;
  cover?: InputMaybe<UpdateCoverInput>;
  dailyViewLimit?: InputMaybe<Scalars['Int']['input']>;
  dailyViewLimitEndDate?: InputMaybe<Scalars['Timestamp']['input']>;
  disabledAdvertisements?: InputMaybe<Array<Scalars['String']['input']>>;
  isAdvertisementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  isSpecialProject?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<PostKind>;
  publishAt?: InputMaybe<Scalars['Timestamp']['input']>;
  recommendations?: InputMaybe<Array<Scalars['ID']['input']>>;
  recommendationsTitle?: InputMaybe<Scalars['String']['input']>;
  responsibleId?: InputMaybe<Scalars['ID']['input']>;
  searchParams?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  specialProjectCost?: InputMaybe<Scalars['Currency']['input']>;
  specialProjectEndDate?: InputMaybe<Scalars['Timestamp']['input']>;
  specialProjectType?: InputMaybe<SpecialProjectType>;
  species?: InputMaybe<PostSpecies>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<PostType>;
};

export type UpdateSettings = {
  theme?: InputMaybe<Theme>;
};

export type UpdateTagInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<UpdateCoverInput>;
  type?: InputMaybe<TagType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  alias: Scalars['String']['output'];
  aliasChangeBlockedUntil?: Maybe<Scalars['Timestamp']['output']>;
  avatar?: Maybe<Avatar>;
  banned: Scalars['Boolean']['output'];
  comments: CommentList;
  contacts: Contacts;
  createdAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Throws ForbiddenException if no access */
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  moderationRequired: Scalars['Boolean']['output'];
  /** Returns null if no access */
  moderatorComment?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  nicknameChangeBlockedUntil?: Maybe<Scalars['Timestamp']['output']>;
  permissions: Array<UserPermission>;
  phone?: Maybe<Scalars['String']['output']>;
  role: UserRole;
  stats: Stats;
  thirdPartyApps: Array<ThirdPartyApp>;
};


export type UserCommentsArgs = {
  filter?: InputMaybe<UserCommentFilterInput>;
  pagination?: InputMaybe<Pagination>;
};

export type UserCommentFilterInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateParams = {
  alias?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UserFindParams = {
  alias?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated, use id or alias */
  identity?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserList = {
  __typename?: 'UserList';
  items: Array<User>;
  pageInfo: PageInfo;
};

export { UserPermission };

export type UserPostsFilter = {
  author: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<PostStatus>>;
};

export { UserRole };

export type UserUpdateParams = {
  alias?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<AvatarParams>;
  contacts?: InputMaybe<ContactsUpdateParams>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  moderatorComment?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  resetThirdPartyApps?: InputMaybe<Array<ThirdPartyApp>>;
  role?: InputMaybe<UserRole>;
};
